import React from "react"
import PropTypes from "prop-types"
import { Box } from "@mui/material"

import { StyledHero } from "./Hero.styled"

import IconBattle from "../../images/icon-battle.svg"

const Hero = ({ children }) => {
  return (
    <StyledHero>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        sx={{ minHeight: `330px` }}
      >
        <Box>{children}</Box>
        <Box
          component="img"
          src={IconBattle}
          alt="battle scene"
          m={0}
          sx={{ width: `100%` }}
        />
      </Box>
    </StyledHero>
  )
}

Hero.propTypes = {
  children: PropTypes.node,
}

export default Hero
