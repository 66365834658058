import React from "react"
import PropTypes from "prop-types"
import { Grid, Box, Typography } from "@mui/material"

import { Container, Section, HammerBreak } from "../Layout"
import { Hero } from "../Hero"

import { StyledCampaign } from "./Campaign.styled"

import HeroHome from "../../images/hero-home.svg"
import IconShadowfiend from "../../images/icon-shadowfiend.svg"
import IconMapbg from "../../images/icon-mapbg.svg"

import contentCampaign from "./campaign.content"

const Campaign = ({ children }) => {
  return (
    <>
      <StyledCampaign>
        <Box>
          <Hero>
            <Container>
              <Box>
                <Grid container>
                  <Grid item md={2}></Grid>
                  <Grid item md={8}>
                    <Section>
                      <Box
                        display="block"
                        component="img"
                        src={HeroHome}
                        alt="bos logo"
                        mb={2}
                        align="center"
                        sx={{ maxWidth: `300px`, margin: `0 auto` }}
                      />
                      <Typography
                        variant="h4"
                        color="textPrimary"
                        align="center"
                        gutterBottom
                      >
                        Recap the D&D 5e adventures of the Fellowship of Helio.
                      </Typography>
                    </Section>
                  </Grid>
                  <Grid item md={2}></Grid>
                </Grid>
              </Box>
            </Container>
          </Hero>
        </Box>
        <Box>
          <Container>
            {contentCampaign.map(({ id, date, title, recap }) => (
              <Box>
                <Box mb={2}>
                  <Typography variant="h6" color="textSecondary" align="center">
                    {date}
                  </Typography>
                  <Typography variant="h4" color="secondary" align="center">
                    {title}
                  </Typography>
                </Box>
                <Box mb={4}>
                  {recap.map(el => (
                    <Typography paragraph color="textSecondary" align="center">
                      {el}
                    </Typography>
                  ))}
                </Box>
                <Box mb={6}>
                  <HammerBreak />
                </Box>
              </Box>
            ))}
          </Container>
        </Box>
      </StyledCampaign>
    </>
  )
}

Campaign.propTypes = {
  children: PropTypes.node,
}

export default Campaign
